import React, {useEffect, useState} from 'react';
import {TextField, Button, Typography, Box, Grid, Card, CardContent, CardActions} from '@mui/material';
import {useVeterinaryAPI} from "../../service/useVeterinaryAPI";
import {useLocation, useSearchParams} from "react-router-dom";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";
import {useTranslation} from "react-i18next";

const VeterinaryForm = ({updateCurrentStep, providedVet}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const location = useLocation();

    const {getVeterinary, updateVeterinary, getVeterinaryByUuid} = useVeterinaryAPI();
    const [errors, setErrors] = useState({});
    const [veterinary, setVeterinary] = useState({
        practice: '',
        name: '',
        phoneNumber: '',
        place: ''
    });

    const [searchParams] = useSearchParams();
    const [readOnly, setReadOnly] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        if (searchParams.get('uuid')) {
            setIsLoading(true)
            setReadOnly(true)
            getVeterinaryByUuid(searchParams.get('uuid'))
                .then(value => setVeterinary(value))
                .catch(() => handleFetchError(t('fetchVeterinaryError')))
                .finally(() => setIsLoading(false));
        } else if (providedVet) {
            setVeterinary(providedVet);
        } else {

            setIsLoading(true)
            getVeterinary()
                .then(value => setVeterinary(value))
                .catch(() => handleFetchError(t('fetchVeterinaryError')))
                .finally(() => setIsLoading(false));
        }
    }, []);

    function handleFetchError(reason) {
        setSnackbarMessage(reason)
        setOpenSnackbar(true)
    }

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.practice = veterinary.practice ? "" : t('practiceRequired');
        tempErrors.name = veterinary.name ? "" : t('veterinarianRequired');
        tempErrors.phoneNumber = veterinary.phoneNumber ? "" : t('phoneNumberRequired');
        tempErrors.place = veterinary.place ? "" : t('locationRequired');
        setErrors({...tempErrors});

        return Object.values(tempErrors).every(x => x === "");
    };

    // Update state for each field
    const handleChange = (event) => {
        const {name, value, type, checked} = event.target;
        setVeterinary(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you would send the reservation object to the backend
        if (validateForm()) {
            setIsLoading(true)
            updateVeterinary(veterinary)
                .then(() => {
                    updateCurrentStep(5)
                    setIsLoading(false)
                })
                .catch(() => {
                    handleFetchError(t('updateVeterinaryError'))
                })
                .finally(setIsLoading(false));

        }
    };

    function goBack() {
        updateCurrentStep(3)
    }

    function updateVet() {
        if (validateForm()) {
            setIsLoading(true)
            updateVeterinary(veterinary)
                .then(value => setVeterinary(value))
                .catch(() => handleFetchError(t('updateVeterinaryError')))
                .finally(() => setIsLoading(false));
        }
    }

    return (
        <>
            {isLoading && (<Loading/>)}
            {!isLoading && (
                <Grid container alignItems="center" justifyContent="center" spacing={1} sx={{textAlign: 'center'}}>
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Typography variant="h5" color="secondary">
                                        {t('veterinaryContactInfo')}
                                    </Typography>
                                    <TextField margin="normal" required fullWidth id="owner-practice" label={t('practice')} name="practice" autoFocus value={veterinary.practice}
                                               onChange={handleChange}
                                               disabled={readOnly}
                                               error={!!errors.practice} helperText={errors.practice}/>
                                    <TextField margin="normal" required fullWidth id="owner-Veterinary" label={t('veterinarian')} name="name" value={veterinary.name}
                                               onChange={handleChange}
                                               error={!!errors.name} helperText={errors.name} disabled={readOnly}/>
                                    <TextField margin="normal" required fullWidth id="contact-phoneNumber" label={t('phoneNumber')} name="phoneNumber" value={veterinary.phoneNumber}
                                               onChange={handleChange} error={!!errors.phoneNumber} helperText={errors.phoneNumber} disabled={readOnly}/>
                                    <TextField margin="normal" required fullWidth id="contact-place" label={t('location')} name="place" value={veterinary.place} onChange={handleChange}
                                               error={!!errors.place} helperText={errors.place} disabled={readOnly}/>

                                </CardContent>
                                <CardActions style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                    {location.pathname !== "/mijn-dierenarts" && (
                                        <>
                                            <Button onClick={goBack} variant="contained" disabled={isLoading}>
                                                {t('previous')}
                                            </Button>
                                            <Button type="submit" variant="contained" disabled={isLoading}>
                                                {t('next')}
                                            </Button>
                                        </>
                                    )}
                                    {!readOnly && location.pathname === "/mijn-dierenarts" && (
                                        <Button onClick={updateVet} variant="contained" disabled={isLoading}>
                                            {t('update')}
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Grid>)}
        </>
    )
};

export default withAuthenticationRequired(VeterinaryForm, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
