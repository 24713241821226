import { useAuth0 } from "@auth0/auth0-react";

export function useServiceFeeAPI() {
    const { getAccessTokenSilently } = useAuth0();

    const fetchWithAuth = async (url, options = {}) => {
        const accessToken = await getAccessTokenSilently();
        const headers = new Headers(options.headers || {});
        headers.append("Authorization", `Bearer ${accessToken}`);
        return fetch(url, { ...options, headers });
    };

    const getServiceFees = async () => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/admin/servicefee`;
            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error("Error fetching service fees:", error);
            throw error;
        }
    };

    const createServiceFee = async (serviceFee) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/admin/servicefee`;
            const response = await fetchWithAuth(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(serviceFee),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error("Error creating service fee:", error);
            throw error;
        }
    };

    const updateServiceFee = async (serviceFee) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/admin/servicefee`;
            const response = await fetchWithAuth(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(serviceFee),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error("Error updating service fee:", error);
            throw error;
        }
    };

    const deleteServiceFee = async (serviceFeeId) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/admin/servicefee/${serviceFeeId}`;
            const response = await fetchWithAuth(url, {
                method: "DELETE",
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.text();
        } catch (error) {
            console.error("Error deleting service fee:", error);
            throw error;
        }
    };

    return {
        getServiceFees,
        createServiceFee,
        updateServiceFee,
        deleteServiceFee,
    };
}
