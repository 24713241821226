import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, Grid, Typography, IconButton } from "@mui/material";
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";
import { useReservationAdminAPI } from "../../service/useReservationAdminAPI";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import DeleteIcon from '@mui/icons-material/Delete';
import { formatDate, formatHour } from "../../common/DateUtil";
import { useTranslation } from "react-i18next";

const PickUpDeliverySchedules = () => {
    const { t } = useTranslation();
    const { getPickUpDeliverSchedules, updatePickUpDeliverSchedules } = useReservationAdminAPI();
    const [schedules, setSchedules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    useEffect(() => {
        getPickUpDeliverSchedules()
            .then(data => setSchedules(data))
            .catch(error => {
                setSnackbarMessage(t('error_fetching_schedules'));
                setOpenSnackbar(true);
            })
            .finally(() => setIsLoading(false));
    }, [t]);

    const handleAddHour = (scheduleId, dayOfWeek, newHour, type) => {
        let deepClone1 = deepClone(schedules);
        deepClone1.forEach(schedule => {
            if (schedule.id === scheduleId) {
                schedule.checkScheduleDays.forEach(day => {
                    if (day.dayOfWeek === dayOfWeek) {
                        day[type] = day[type] ? [...new Set([...day[type], newHour])].sort() : [newHour];
                    }
                });
            }
        });
        setSchedules(deepClone1);
    };

    const handleRemoveHour = (scheduleId, dayOfWeek, hourToRemove, type) => {
        let deepClone1 = deepClone(schedules);
        deepClone1.forEach(schedule => {
            if (schedule.id === scheduleId) {
                schedule.checkScheduleDays.forEach(day => {
                    if (day.dayOfWeek === dayOfWeek) {
                        day[type] = day[type] ? day[type].filter(hour => hour !== hourToRemove) : [];
                    }
                });
            }
        });
        setSchedules(deepClone1);
    };

    const handleUpdate = () => {
        updatePickUpDeliverSchedules(schedules).catch(() => console.log("Something went wrong"));
    };

    return (
        <>
            {isLoading ? <Loading /> : (
                <Grid container spacing={2} style={{ padding: '100px' }}>
                    <Button onClick={handleUpdate} variant="contained">
                        {t("save_configuration")}
                    </Button>
                    {schedules.map((schedule) => (
                        <Grid item xs={12} key={schedule.id}>
                            <Typography variant="h6" style={{ marginBottom: '20px' }}>
                                {t("schedule_period", { startPeriod: formatDate(schedule.startPeriod), endPeriod: formatDate(schedule.endPeriod) })}
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table size="small" aria-label="schedule table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t("day_of_week")}</TableCell>
                                            <TableCell>{t("add_check_in_hours")}</TableCell>
                                            <TableCell>{t("check_in_hours")}</TableCell>
                                            <TableCell>{t("add_check_out_hours")}</TableCell>
                                            <TableCell>{t("check_out_hours")}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {schedule.checkScheduleDays.map((checkScheduleDay, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{checkScheduleDay.dayOfWeek}</TableCell>
                                                <TableCell>
                                                    <Select
                                                        value=""
                                                        displayEmpty
                                                        onChange={(e) => handleAddHour(schedule.id, checkScheduleDay.dayOfWeek, e.target.value, "checkInHours")}
                                                        inputProps={{ 'aria-label': t("none") }}
                                                        style={{ marginTop: '10px' }}
                                                    >
                                                        <MenuItem value=""><em>{t("none")}</em></MenuItem>
                                                        {Array.from({ length: 7 }, (_, i) => {
                                                            const hour = 16 + Math.floor(i / 2);
                                                            const minute = i % 2 === 0 ? '00' : '30';
                                                            return `${hour.toString().padStart(2, '0')}:${minute}`;
                                                        }).map(option => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    {checkScheduleDay.checkInHours.map(hour => (
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }} key={hour}>
                                                            <p>
                                                                {formatHour(hour)}
                                                                <IconButton
                                                                    size="small"
                                                                    color="error"
                                                                    onClick={() => handleRemoveHour(schedule.id, checkScheduleDay.dayOfWeek, hour, "checkInHours")}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        value=""
                                                        displayEmpty
                                                        onChange={(e) => handleAddHour(schedule.id, checkScheduleDay.dayOfWeek, e.target.value, "checkOutHours")}
                                                        inputProps={{ 'aria-label': t("none") }}
                                                        style={{ marginTop: '10px' }}
                                                    >
                                                        <MenuItem value=""><em>{t("none")}</em></MenuItem>
                                                        {Array.from({ length: 15 }, (_, i) => {
                                                            const hour = 8 + Math.floor(i / 2);
                                                            const minute = i % 2 === 0 ? '00' : '30';
                                                            return `${hour.toString().padStart(2, '0')}:${minute}`;
                                                        }).map(option => (
                                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                                <TableCell>
                                                    {checkScheduleDay.checkOutHours.map(hour => (
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }} key={hour}>
                                                            <p>
                                                                {formatHour(hour)}
                                                                <IconButton
                                                                    size="small"
                                                                    color="error"
                                                                    onClick={() => handleRemoveHour(schedule.id, checkScheduleDay.dayOfWeek, hour, "checkOutHours")}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </p>
                                                        </div>
                                                    ))}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    ))}
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar} />
                </Grid>
            )}
        </>
    );
};

export default withAuthenticationRequired(PickUpDeliverySchedules, {
    onRedirecting: () => <Loading />,
    returnTo: '/login'
});
