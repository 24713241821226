import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: {
                // DayView Component
                "cancelBooking": "Cancel Booking",
                "confirmCancellation": "Are you sure you want to cancel this booking?",
                "confirmEditReservation": "You are about to edit this reservation.",
                "checkIn": "Check-in",
                "checkOut": "Check-out",
                "numberOfCats": "Number of Cats",
                "owner": "Owner",
                "veterinarian": "Veterinarian",
                "actions": "Actions",
                "yes": "Yes",
                "no": "No",
                "save": "Save",
                "cancel": "Cancel",
                "editReservation": "Edit Reservation",

                // New Component
                "enterCatName": "Enter your cat's name",
                "catInfo": "Information about {{name}}",
                "name": "Name",
                "age": "Age",
                "breed": "Breed",
                "sex": "Sex",
                "male": "Male",
                "female": "Female",
                "unknown": "Unknown",
                "color": "Color",
                "chipNumber": "Chip Number",
                "medicalInfo": "Medical information of {{name}}",
                "neutered": "Spayed/Neutered",
                "dewormed": "Dewormed & Flea Treated",
                "leukemia": "Cat Leukemia (leucose)",
                "panleukopenia": "Panleukopenia (panleucopenie / typhus)",
                "coryza": "Coryza",
                "behavior": "Behavior of {{name}}",
                "homeBehavior": "Home Behavior",
                "diet": "Diet",
                "medicalHistory": "Medical history",
                "interactionWithAnimals": "Interaction with Other Animals",
                "allowContact": "Allowed to contact other cats",
                "vaccinationWarning": "Vaccinations are mandatory for staying at Catland hotel and will be checked at check-in. If vaccinations are incomplete, your cat will be denied entry.",
                "previous": "Previous",
                "next": "Next",
                "update": "Update",
                "fillData": "Fill in the information for {{name}}",
                "submit": "Submit",
                "loginPrompt": "Log in to make a reservation.",
                "backToCatland": "Back to Catland",
                "goToLogin": "Proceed to Login Screen",
                "language": "Language",
                "myReservations": "My Reservations",
                "myCats": "My Cats",
                "myVet": "My Vet",
                "myProfile": "My Profile",
                "emergencyContact": "Emergency Contact",
                "reservationOverview": "Reservation Overview",
                "serviceFee": "Service Fee",
                "pickUpDeliverySchedule": "Pick-Up/Delivery Schedule",
                "newReservations": "New Reservations",
                "makeReservation": "Make a Reservation",
                "newReservationsError": "Could not fetch new reservations count",
                "fetchReservationsError": "Failed to retrieve my reservations",
                "for": "For",
                "stayType": "Stay Type",
                "status": "Status",
                "addCatTitle": "Start here to add a new cat.",
                "catNameLabel": "Cat's name",
                "selectForStay": "Select for stay",
                "addCatPlaceholder": "Add a cat",
                "continueToCalendar": "Continue to calendar",
                "fetchCatsForReservationError": "Could not fetch cats for reservation",
                "fetchMyCatsError": "Could not fetch my cats",
                "addCatError": "Could not add a new cat, please try again.",
                "selectCheckInDate": "Select the check-in date",
                "checkInDateLabel": "Check-in date",
                "dateUnavailableWarning": "Check-in is in the past and is no longer available!",
                "selectCheckOutDate": "Select the check-out date",
                "checkOutDateLabel": "Check-out date",
                "dateBeforeWarning": "Check-out is before check-in!",
                "selectStayTitle": "Select your stay",
                "selectStay": "Select stay",
                "multipleStayXL": "€32 / day for 2 cats in 1 XL stay",
                "multipleStayStandard": "€27 / day for 2 cats in 2 stays with 'connecting door'",
                "singleStayXL": "€20 / day for 1 cat in XL stay",
                "singleStayStandard": "€15 / day for 1 cat in standard stay",
                "noAvailableStays": "No stays available for the selected period.",
                "updateDates": "Enter new check-in/check-out dates",
                "checkInHourTitle": "Specify the check-in hour",
                "noCheckInHoursAvailable": "No check-in hours are available at the moment. You can proceed with the reservation, and Catland will contact you to arrange the check-in time.",
                "checkOutHourTitle": "Specify the check-out hour",
                "noCheckOutHoursAvailable": "No check-out hours are available at the moment. You can proceed with the reservation, and Catland will contact you to arrange the check-out time.",
                "personalInformation": "Personal Information",
                "firstName": "First Name",
                "street": "Street",
                "houseNumber": "House Number",
                "apartment": "Apartment",
                "postalCode": "Postal Code",
                "municipality": "Municipality",
                "country": "Country",
                "gsm": "Mobile Number",
                "email": "Email",
                "nameRequired": "Name is a required field.",
                "firstNameRequired": "First Name is a required field.",
                "streetRequired": "Street is a required field.",
                "houseNumberRequired": "House Number is a required field.",
                "postalCodeRequired": "Postal Code is a required field.",
                "municipalityRequired": "Municipality is a required field.",
                "countryRequired": "Country is a required field.",
                "gsmRequired": "Mobile Number is a required field.",
                "emailRequired": "Email is a required field.",
                "invalidEmail": "Invalid email.",
                "fetchPersonInfoError": "Failed to retrieve person data.",
                "updatePersonInfoError": "Failed to update person data.",
                "veterinaryContactInfo": "Veterinary Contact Information",
                "practice": "Practice",
                "phoneNumber": "Phone Number",
                "location": "Location",
                "practiceRequired": "Practice is a required field.",
                "veterinarianRequired": "Veterinarian is a required field.",
                "phoneNumberRequired": "Phone Number is a required field.",
                "locationRequired": "Location is a required field.",
                "fetchVeterinaryError": "Failed to retrieve veterinarian information.",
                "updateVeterinaryError": "Failed to update veterinarian information.",
                "checkCatVaccinations": "How do I check my cat's vaccinations?",
                "emergencyContactTitle": "Emergency Contact if Unreachable",
                "fetchContactInfoError": "Failed to retrieve contact information.",
                "updateContactInfoError": "Failed to update contact information.",
                "reservationOverviewTitle": "Reservation Overview",
                "reservationNumber": "Reservation Number",
                "reservationPeriod": "Reservation for Period",
                "veterinaryPractice": "Veterinary Practice",
                "reservationFor": "Reservation for",
                "selectedStay": "Selected Stay",
                "reservationPrice": "Reservation Price",
                "serviceFeeIncluded": "Service fee of 1.5€ included",
                "advance": "Advance Payment",
                "confirmReservation": "Confirm Your Reservation",
                "fetchReservationError": "Failed to retrieve reservation overview.",
                "fetchPaymentLinkError": "Failed to retrieve payment link.",
                "itemsPerPage": "Items per page",
                "add_new_service_fee": "Add New Service Fee",
                "service_fees": "Service Fees",
                "start_date": "Start Date",
                "end_date": "End Date",
                "amount": "Amount",
                "edit": "Edit",
                "save_configuration": "Save Configuration",
                "schedule_period": "Schedule from {{startPeriod}} to {{endPeriod}}",
                "day_of_week": "Day of Week",
                "add_check_in_hours": "Add Check-in Hours",
                "check_in_hours": "Check-in Hours",
                "add_check_out_hours": "Add Check-out Hours",
                "check_out_hours": "Check-out Hours",
                "none": "None",
                "error_fetching_schedules": "Error fetching schedules",
                "new_reservations_error": "Unable to fetch new reservations",
                "download_contracts_error": "Failed to download contracts",
                "validate_reservation_error": "Failed to validate the new reservation",
                "check_in": "Check-in",
                "check_out": "Check-out",
                "stay_types": "Stay Types",
                "price": "Price",
                "cats": "Cat(s)",
                "update_reservation": "You are about to modify the reservation.",
                "countries": {
                    "Belgium": "Belgium",
                    "France": "France",
                    "Luxembourg": "Luxembourg",
                    "Netherlands": "Netherlands"
                },
                "vacations": "Vacations",
                "add_new_vacations": "Add new vacation"
            }
        },
        fr: {
            translation: {
                // DayView Component
                "cancelBooking": "Annuler la réservation",
                "confirmCancellation": "Êtes-vous sûr de vouloir annuler cette réservation?",
                "confirmEditReservation": "Vous êtes sur le point de modifier cette réservation.",
                "checkIn": "Enregistrement",
                "checkOut": "Départ",
                "numberOfCats": "Nombre de chats",
                "owner": "Propriétaire",
                "veterinarian": "Vétérinaire",
                "actions": "Actions",
                "yes": "Oui",
                "no": "Non",
                "save": "Enregistrer",
                "cancel": "Annuler",
                "editReservation": "Modifier la réservation",

                // New Component
                "enterCatName": "Entrez le nom de votre chat",
                "catInfo": "Informations sur {{name}}",
                "name": "Nom",
                "age": "Âge",
                "breed": "Race",
                "sex": "Sexe",
                "male": "Mâle",
                "female": "Femelle",
                "unknown": "Inconnu",
                "color": "Couleur",
                "chipNumber": "Numéro de puce",
                "medicalInfo": "Informations médicales de {{name}}",
                "neutered": "Stérilisé",
                "dewormed": "Dépucé & Déparasité",
                "leukemia": "Leucémie féline (leucose)",
                "panleukopenia": "Panleucopénie (panleucopenie / typhus)",
                "coryza": "Coryza",
                "behavior": "Comportement de {{name}}",
                "homeBehavior": "Comportement à la maison",
                "diet": "Régime alimentaire",
                "medicalHistory": "Antécédents médicaux",
                "interactionWithAnimals": "Interaction avec d'autres animaux",
                "allowContact": "Autorisé à entrer en contact avec d'autres chats",
                "vaccinationWarning": "Les vaccinations sont obligatoires pour séjourner à l'hôtel Catland et seront vérifiées lors de l'enregistrement. Si les vaccinations sont incomplètes, l'entrée de votre chat sera refusée.",
                "previous": "Précédent",
                "next": "Suivant",
                "update": "Mettre à jour",
                "fillData": "Remplissez les informations pour {{name}}",
                "submit": "Soumettre",
                "loginPrompt": "Connectez-vous pour faire une réservation.",
                "backToCatland": "Retour à Catland",
                "goToLogin": "Aller à l'écran de connexion",
                "language": "Langue",
                "myReservations": "Mes Réservations",
                "myCats": "Mes Chats",
                "myVet": "Mon Vétérinaire",
                "myProfile": "Mon Profil",
                "emergencyContact": "Contact d'urgence",
                "reservationOverview": "Vue d'ensemble des réservations",
                "serviceFee": "Frais de service",
                "pickUpDeliverySchedule": "Horaire de ramassage/livraison",
                "newReservations": "Nouvelles réservations",
                "newReservationsError": "Impossible de récupérer le nombre de nouvelles réservations",
                "fetchReservationsError": "Échec de la récupération de mes réservations",
                "for": "Pour",
                "stayType": "Type de séjour",
                "status": "Statut",
                "makeReservation": "Faire une réservation",
                "addCatTitle": "Commencez ici pour ajouter un nouveau chat.",
                "catNameLabel": "Nom du chat",
                "selectForStay": "Sélectionner pour le séjour",
                "addCatPlaceholder": "Ajouter un chat",
                "continueToCalendar": "Continuer vers le calendrier",
                "fetchCatsForReservationError": "Impossible de récupérer les chats pour la réservation",
                "fetchMyCatsError": "Impossible de récupérer mes chats",
                "addCatError": "Impossible d'ajouter un nouveau chat, veuillez réessayer.",
                "selectCheckInDate": "Sélectionnez la date d'arrivée",
                "checkInDateLabel": "Date d'arrivée",
                "dateUnavailableWarning": "L'arrivée est dans le passé et n'est plus disponible!",
                "selectCheckOutDate": "Sélectionnez la date de départ",
                "checkOutDateLabel": "Date de départ",
                "dateBeforeWarning": "Le départ est avant l'arrivée!",
                "selectStayTitle": "Sélectionnez votre séjour",
                "selectStay": "Sélectionnez le séjour",
                "multipleStayXL": "32 € / jour pour 2 chats dans 1 séjour XL",
                "multipleStayStandard": "27 € / jour pour 2 chats dans 2 séjours avec 'porte communicante'",
                "singleStayXL": "20 € / jour pour 1 chat dans le séjour XL",
                "singleStayStandard": "15 € / jour pour 1 chat dans le séjour standard",
                "noAvailableStays": "Aucun séjour disponible pour la période sélectionnée.",
                "updateDates": "Entrez de nouvelles dates d'arrivée/départ",
                "checkInHourTitle": "Indiquez l'heure d'arrivée",
                "noCheckInHoursAvailable": "Il n'y a pas d'heures d'arrivée disponibles pour le moment. Vous pouvez continuer la réservation, et Catland vous contactera pour fixer l'heure d'arrivée.",
                "checkOutHourTitle": "Indiquez l'heure de départ",
                "noCheckOutHoursAvailable": "Il n'y a pas d'heures de départ disponibles pour le moment. Vous pouvez continuer la réservation, et Catland vous contactera pour fixer l'heure de départ.",
                "personalInformation": "Informations personnelles",
                "firstName": "Prénom",
                "street": "Rue",
                "houseNumber": "Numéro",
                "apartment": "Appartement",
                "postalCode": "Code postal",
                "municipality": "Commune",
                "country": "Pays",
                "gsm": "Numéro de mobile",
                "email": "E-mail",
                "nameRequired": "Le nom est obligatoire.",
                "firstNameRequired": "Le prénom est obligatoire.",
                "streetRequired": "La rue est obligatoire.",
                "houseNumberRequired": "Le numéro est obligatoire.",
                "postalCodeRequired": "Le code postal est obligatoire.",
                "municipalityRequired": "La commune est obligatoire.",
                "countryRequired": "Le pays est obligatoire.",
                "gsmRequired": "Le numéro de mobile est obligatoire.",
                "emailRequired": "L'e-mail est obligatoire.",
                "invalidEmail": "E-mail non valide.",
                "fetchPersonInfoError": "Échec de la récupération des données de la personne.",
                "updatePersonInfoError": "Échec de la mise à jour des données de la personne.",
                "veterinaryContactInfo": "Informations de contact vétérinaire",
                "practice": "Cabinet",
                "phoneNumber": "Numéro de téléphone",
                "location": "Localisation",
                "practiceRequired": "Le cabinet est obligatoire.",
                "veterinarianRequired": "Le vétérinaire est obligatoire.",
                "phoneNumberRequired": "Le numéro de téléphone est obligatoire.",
                "locationRequired": "La localisation est obligatoire.",
                "fetchVeterinaryError": "Échec de la récupération des informations du vétérinaire.",
                "updateVeterinaryError": "Échec de la mise à jour des informations du vétérinaire.",
                "checkCatVaccinations": "Comment vérifier les vaccins de mon chat ?",
                "emergencyContactTitle": "Contact d'urgence si injoignable",
                "fetchContactInfoError": "Échec de la récupération des informations de contact.",
                "updateContactInfoError": "Échec de la mise à jour des informations de contact.",
                "reservationOverviewTitle": "Aperçu de la réservation",
                "reservationNumber": "Numéro de réservation",
                "reservationPeriod": "Période de réservation",
                "veterinaryPractice": "Cabinet vétérinaire",
                "reservationFor": "Réservation pour",
                "selectedStay": "Séjour sélectionné",
                "reservationPrice": "Prix de la réservation",
                "serviceFeeIncluded": "Frais de service de 1,5€ inclus",
                "advance": "Acompte",
                "confirmReservation": "Confirmez votre réservation",
                "fetchReservationError": "Échec de la récupération de l'aperçu de la réservation.",
                "fetchPaymentLinkError": "Échec de la récupération du lien de paiement.",
                "itemsPerPage": "Lignes par page",
                "add_new_service_fee": "Ajouter une Nouvelle Frais de Service",
                "service_fees": "Frais de Service",
                "start_date": "Date de Début",
                "end_date": "Date de Fin",
                "amount": "Montant",
                "edit": "Modifier",
                "save_configuration": "Enregistrer la Configuration",
                "schedule_period": "Horaire du {{startPeriod}} au {{endPeriod}}",
                "day_of_week": "Jour de la Semaine",
                "add_check_in_hours": "Ajouter les Heures d'Arrivée",
                "check_in_hours": "Heures d'Arrivée",
                "add_check_out_hours": "Ajouter les Heures de Départ",
                "check_out_hours": "Heures de Départ",
                "none": "Aucun",
                "error_fetching_schedules": "Erreur lors de la récupération des horaires",
                "new_reservations_error": "Impossible de récupérer les nouvelles réservations",
                "download_contracts_error": "Échec du téléchargement des contrats",
                "validate_reservation_error": "Échec de la validation de la nouvelle réservation",
                "check_in": "Arrivée",
                "check_out": "Départ",
                "stay_types": "Types de séjour",
                "price": "Prix",
                "cats": "Chat(s)",
                "update_reservation": "Vous êtes sur le point de modifier la réservation.",
                "countries": {
                    "Belgium": "Belgique",
                    "France": "France",
                    "Luxembourg": "Luxembourg",
                    "Netherlands": "Pays-Bas"
                },
                "vacations": "Vacances",
                "add_new_vacations": "Ajouter de nouveaux jours fériés"
            }
        },
        nl: {
            translation: {
                // DayView Component
                "cancelBooking": "Boeking annuleren",
                "confirmCancellation": "Weet u zeker dat u deze booking wil annuleren?",
                "confirmEditReservation": "U staat op het punt reservatie aan te passen.",
                "checkIn": "Check-in",
                "checkOut": "Check-out",
                "numberOfCats": "Aantal Kat(ten)",
                "owner": "Eigenaar",
                "veterinarian": "Dierenarts",
                "actions": "Acties",
                "yes": "Ja",
                "no": "Nee",
                "save": "Opslaan",
                "cancel": "Annuleren",
                "editReservation": "Reservatie aanpassen",

                // New Component
                "enterCatName": "Geef de naam in van je kat",
                "catInfo": "Gegevens over {{name}}",
                "name": "Naam",
                "age": "Leeftijd",
                "breed": "Ras",
                "sex": "Geslacht",
                "male": "Mannelijk",
                "female": "Vrouwelijk",
                "unknown": "Onbekend",
                "color": "Kleur",
                "chipNumber": "Chipnummer",
                "medicalInfo": "Medische informatie van {{name}}",
                "neutered": "Castratie/sterilisatie",
                "dewormed": "Ontvlooit & ontwormt",
                "leukemia": "Kattenleukemie (leucose)",
                "panleukopenia": "Kattenziekte (panleucopenie / typhus)",
                "coryza": "Niesziekte (rhinotracheïtis / coryza)",
                "behavior": "Gedrag van {{name}}",
                "homeBehavior": "Gedrag thuis",
                "diet": "Dieet",
                "medicalHistory": "Medische geschiedenis",
                "interactionWithAnimals": "Omgang met andere dieren",
                "allowContact": "Mag in contact komen met andere katten",
                "vaccinationWarning": "Vaccinaties zijn verplicht voor een verblijf bij kattenhotel Catland, dit wordt steeds gecontroleerd bij check-in. Wanneer vaccinaties niet volledig zijn, zal uw kat geweigerd worden!",
                "previous": "Vorige",
                "next": "Volgende",
                "update": "Update",
                "fillData": "Vul de gegevens aan van {{name}}",
                "submit": "Verzenden",
                "loginPrompt": "Log in om een reservatie te maken.",
                "backToCatland": "Terug naar Catland",
                "goToLogin": "Verder gaan naar inlogscherm",
                "myReservations": "Mijn Reservaties",
                "language": "Taal",
                "myCats": "Mijn Katten",
                "myVet": "Mijn Dierenarts",
                "myProfile": "Mijn Profiel",
                "emergencyContact": "Noodcontact",
                "reservationOverview": "Reservatie overzicht",
                "serviceFee": "Service Fee",
                "pickUpDeliverySchedule": "PickUpDeliverySchedule",
                "newReservations": "Nieuwe reservaties",
                "newReservationsError": "Aantal nieuwe reservaties kon niet opgehaald worden",
                "fetchReservationsError": "Mijn reservaties ophalen mislukt",
                "for": "Voor",
                "stayType": "Verblijf type",
                "status": "Status",
                "makeReservation": "Maak een reservatie",
                "addCatTitle": "Start hier om een nieuwe kat toe te voegen.",
                "catNameLabel": "Naam van de kat",
                "selectForStay": "Selecteer voor verblijf",
                "addCatPlaceholder": "Voeg een kat toe",
                "continueToCalendar": "Doorgaan naar kalender",
                "fetchCatsForReservationError": "Katten voor reservatie konden niet opgehaald worden",
                "fetchMyCatsError": "Mijn katten konden niet worden opgehaald",
                "addCatError": "Nieuwe kat kan niet toegevoegd worden, probeer opnieuw.",
                "selectCheckInDate": "Selecteer de check-in datum",
                "checkInDateLabel": "Check-in datum",
                "dateUnavailableWarning": "Check-in ligt in het verleden en is niet meer beschikbaar!",
                "selectCheckOutDate": "Selecteer de check-out datum",
                "checkOutDateLabel": "Check-out datum",
                "dateBeforeWarning": "Check-out ligt voor check-in!",
                "selectStayTitle": "Selecteer uw verblijf",
                "selectStay": "Selecteer verblijf",
                "multipleStayXL": "32 € / dag voor 2 katten in 1 XL verblijf",
                "multipleStayStandard": "27 € / dag voor 2 katten in 2 verblijven met 'connecting door'",
                "singleStayXL": "20 € / dag voor 1 kat in het XL verblijf",
                "singleStayStandard": "15 € / dag voor 1 kat in het standaard verblijf",
                "noAvailableStays": "Geen verblijven meer beschikbaar voor de opgegeven periode.",
                "updateDates": "Geef nieuwe check in/check out data in",
                "checkInHourTitle": "Geef het uur van de check-in op",
                "noCheckInHoursAvailable": "Er zijn geen check-in uren beschikbaar momenteel. U kan de reservatie verder afronden, Catland zal u contacteren om het check-in uur af te spreken.",
                "checkOutHourTitle": "Geef het uur van de check-out op",
                "noCheckOutHoursAvailable": "Er zijn geen check-out uren beschikbaar momenteel. U kan de reservatie verder afronden, Catland zal u contacteren om het check-out uur af te spreken.",
                "personalInformation": "Persoonlijke informatie",
                "firstName": "Voornaam",
                "street": "Straat",
                "houseNumber": "Huisnummer",
                "apartment": "Bus",
                "postalCode": "Postcode",
                "municipality": "Gemeente",
                "country": "Land",
                "gsm": "GSM",
                "email": "E-mail",
                "nameRequired": "Naam is een verplicht veld.",
                "firstNameRequired": "Voornaam is een verplicht veld.",
                "streetRequired": "Straat is een verplicht veld.",
                "houseNumberRequired": "Huisnummer is een verplicht veld.",
                "postalCodeRequired": "Postcode is een verplicht veld.",
                "municipalityRequired": "Gemeente is een verplicht veld.",
                "countryRequired": "Land is een verplicht veld.",
                "gsmRequired": "GSM is een verplicht veld.",
                "emailRequired": "E-mail is een verplicht veld.",
                "invalidEmail": "E-mail niet geldig.",
                "fetchPersonInfoError": "Persoon data ophalen mislukt.",
                "updatePersonInfoError": "Updaten persoon data mislukt.",
                "veterinaryContactInfo": "Contactinformatie Dierenarts",
                "practice": "Praktijk",
                "phoneNumber": "Telefoonnummer",
                "location": "Plaats",
                "practiceRequired": "Praktijk is een verplicht veld.",
                "veterinarianRequired": "Dierenarts is een verplicht veld.",
                "phoneNumberRequired": "Telefoonnummer is een verplicht veld.",
                "locationRequired": "Plaats is een verplicht veld.",
                "fetchVeterinaryError": "Dierenarts ophalen mislukt.",
                "updateVeterinaryError": "Updaten dierenarts mislukt.",
                "checkCatVaccinations": "Hoe controleer ik de vaccinaties van mijn kat?",
                "emergencyContactTitle": "Noodcontact bij niet bereikbaar",
                "fetchContactInfoError": "Persoon data ophalen mislukt.",
                "updateContactInfoError": "Updaten persoon data mislukt.",
                "reservationOverviewTitle": "Reservatie overzicht",
                "reservationNumber": "Reservatie nummer",
                "reservationPeriod": "Reservatie voor periode",
                "veterinaryPractice": "Dierenartsen praktijk",
                "reservationFor": "Reservatie voor",
                "selectedStay": "Geselecteerd verblijf",
                "reservationPrice": "Prijs van de reservatie",
                "serviceFeeIncluded": "Service fee van 1,5€ inbegrepen",
                "advance": "Voorschot",
                "confirmReservation": "Bevestig uw reservatie",
                "fetchReservationError": "Reservatie overzicht ophalen mislukt.",
                "fetchPaymentLinkError": "Betaallink ophalen mislukt.",
                "itemsPerPage": "Rijen per pagina",
                "add_new_service_fee": "Nieuwe Servicevergoeding Toevoegen",
                "service_fees": "Servicekosten",
                "start_date": "Startdatum",
                "end_date": "Einddatum",
                "amount": "Bedrag",
                "edit": "Bewerken",
                "save_configuration": "Configuratie opslaan",
                "schedule_period": "Schema van {{startPeriod}} tot {{endPeriod}}",
                "day_of_week": "Dag van de Week",
                "add_check_in_hours": "Check-in Uren Toevoegen",
                "check_in_hours": "Check-in Uren",
                "add_check_out_hours": "Check-out Uren Toevoegen",
                "check_out_hours": "Check-out Uren",
                "none": "Geen",
                "error_fetching_schedules": "Fout bij het ophalen van schema's",
                "new_reservations_error": "Nieuwe reservaties kunnen niet opgehaald worden",
                "download_contracts_error": "Contracten downloaden mislukt",
                "validate_reservation_error": "Valideren van de nieuwe reservatie mislukt",
                "check_in": "Check-in",
                "check_out": "Check-out",
                "stay_types": "Verblijven",
                "price": "Prijs",
                "cats": "Kat(ten)",
                "update_reservation": "U staat op het punt reservatie aan te passen.",
                "countries": {
                    "Belgium": "België",
                    "France": "Frankrijk",
                    "Luxembourg": "Luxemburg",
                    "Netherlands": "Nederland"
                },
                "vacations": "Vakanties",
                "add_new_vacations": "Nieuwe vakantie toevoegen"
            }
        }
    },
    lng: "nl",
    fallbackLng: "nl",
    interpolation: {
        escapeValue: false,
    }
});

export default i18n;
