import {useAuth0} from "@auth0/auth0-react";
import {formatDate, toLocalDate, toLocalDateReversed} from "../common/DateUtil";

export function useVacationsAPI() {
    const {getAccessTokenSilently} = useAuth0();

    const fetchWithAuth = async (url, options = {}) => {
        const accessToken = await getAccessTokenSilently();
        const headers = new Headers(options.headers || {});
        headers.append("Authorization", `Bearer ${accessToken}`);
        return fetch(url, {...options, headers});
    };

    const getVacations = async (year) => {
        try {
            const fromDate = `${year}-01-01`;
            const toDate = `${year}-12-31`;

            const url = `${process.env.REACT_APP_BACK_API}/admin/vacations?fromDate=${fromDate}&toDate=${toDate}`;

            const response = await fetchWithAuth(url);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response.json();
        } catch (error) {
            console.error("Error fetching vacations:", error);
            throw error;
        }
    };

    const createVacation = async (vacation) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/admin/vacations?fromDate=${vacation.fromDate}&toDate=${vacation.toDate}`;
            const response = await fetchWithAuth(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response;
        } catch (error) {
            console.error("Error creating vacations:", error);
            throw error;
        }
    };

    const deleteVacation = async (vacation) => {
        try {
            const url = `${process.env.REACT_APP_BACK_API}/admin/vacations?fromDate=${vacation.fromDate}&toDate=${vacation.toDate}`;
            const response = await fetchWithAuth(url, {
                method: "DELETE",
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            return await response;
        } catch (error) {
            console.error("Error deleting vacations:", error);
            throw error;
        }
    };

    return {
        getVacations,
        createVacation,
        deleteVacation
    };
}
