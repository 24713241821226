import React, { useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, IconButton, Grid, Typography } from "@mui/material";
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";
import { useServiceFeeAPI } from "../../service/useServicefeeAPI";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {formatDate} from "../../common/DateUtil";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useTranslation} from "react-i18next";

const ServiceFees = () => {
    const { t } = useTranslation();
    const { getServiceFees, createServiceFee, updateServiceFee, deleteServiceFee } = useServiceFeeAPI();
    const [serviceFees, setServiceFees] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isEditing, setIsEditing] = useState(null);

    useEffect(() => {
        getServiceFees()
            .then(data => setServiceFees(data))
            .catch(error => {
                setSnackbarMessage(t("error_fetching_service_fees"));
                setOpenSnackbar(true);
            })
            .finally(() => setIsLoading(false));
    }, [t]);

    const handleAddServiceFee = () => {
        const newServiceFee = { description: "", amount: 0 };
        setServiceFees([...serviceFees, newServiceFee]);
        setIsEditing(serviceFees.length);
    };

    const handleEdit = (index, key, value) => {
        const updatedFees = [...serviceFees];
        updatedFees[index][key] = key === "startDate" || key === "endDate" ? new Date(value) : value;
        setServiceFees(updatedFees);
    };

    const handleSave = (index) => {
        const serviceFee = serviceFees[index];
        if (serviceFee.id) {
            updateServiceFee(serviceFee).catch(() => {
                setSnackbarMessage(t("error_updating_service_fee"));
                setOpenSnackbar(true);
            });
        } else {
            createServiceFee(serviceFee)
                .then(newFee => {
                    const updatedFees = [...serviceFees];
                    updatedFees[index] = newFee;
                    setServiceFees(updatedFees);
                })
                .catch(() => {
                    setSnackbarMessage(t("error_creating_service_fee"));
                    setOpenSnackbar(true);
                });
        }
        setIsEditing(null);
    };

    const handleDelete = (index) => {
        const serviceFee = serviceFees[index];
        if (serviceFee.id) {
            deleteServiceFee(serviceFee.id)
                .then(() => setServiceFees(serviceFees.filter((_, i) => i !== index)))
                .catch(() => {
                    setSnackbarMessage(t("error_deleting_service_fee"));
                    setOpenSnackbar(true);
                });
        } else {
            setServiceFees(serviceFees.filter((_, i) => i !== index));
        }
    };

    const parseDate = (dateString) => new Date(dateString);

    return (
        <>
            {isLoading ? <Loading /> : (
                <Grid container spacing={2} style={{ padding: '100px' }}>
                    <Button onClick={handleAddServiceFee} variant="contained" startIcon={<AddIcon />}>
                        {t("add_new_service_fee")}
                    </Button>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{ marginBottom: '20px' }}>{t("service_fees")}</Typography>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="service fee table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("start_date")}</TableCell>
                                        <TableCell>{t("end_date")}</TableCell>
                                        <TableCell>{t("amount")}</TableCell>
                                        <TableCell>{t("actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {serviceFees.map((fee, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {isEditing === index ? (
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label={t("start_date")}
                                                            value={parseDate(fee.startDate)}
                                                            onChange={(newValue) => handleEdit(index, "startDate", newValue)}
                                                            inputFormat="dd-MM-yyyy"
                                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                                        />
                                                    </LocalizationProvider>
                                                ) : (
                                                    formatDate(fee.startDate)
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {isEditing === index ? (
                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                        <DatePicker
                                                            label={t("end_date")}
                                                            value={parseDate(fee.endDate)}
                                                            onChange={(newValue) => handleEdit(index, "endDate", newValue)}
                                                            renderInput={(params) => <TextField {...params} fullWidth />}
                                                        />
                                                    </LocalizationProvider>
                                                ) : (
                                                    formatDate(fee.endDate)
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {isEditing === index ? (
                                                    <TextField
                                                        type="number"
                                                        inputProps={{ step: "0.01" }}
                                                        value={fee.amount}
                                                        onChange={(e) => handleEdit(index, "amount", parseFloat(e.target.value))}
                                                        fullWidth
                                                    />
                                                ) : (
                                                    fee.amount
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {isEditing === index ? (
                                                    <>
                                                        <Button onClick={() => handleSave(index)} variant="contained" color="primary">
                                                            {t('save')}
                                                        </Button>
                                                        <Button onClick={() => setIsEditing(null)} color="secondary">
                                                            {t('cancel')}
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button onClick={() => setIsEditing(index)} color="primary">
                                                            {t('edit')}
                                                        </Button>
                                                        <IconButton onClick={() => handleDelete(index)} color="error">
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar} />
                </Grid>
            )}
        </>
    );
};

export default withAuthenticationRequired(ServiceFees, {
    onRedirecting: () => <Loading />,
    returnTo: '/login'
});
