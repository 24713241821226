import React, {useEffect, useState} from "react";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, IconButton, Grid, Typography, Select, MenuItem, Box} from "@mui/material";
import Loading from "../common/Loading";
import GetErrorSnackbar from "../../common/Snackbar";
import {useVacationsAPI} from "../../service/useVacationsAPI";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {formatDate, toLocalDate} from "../../common/DateUtil";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useTranslation} from "react-i18next";
import nl from "date-fns/locale/nl";

const Vacations = () => {
    const {t} = useTranslation();
    const {getVacations, createVacation, deleteVacation} = useVacationsAPI();
    const [vacations, setVacations] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isEditing, setIsEditing] = useState(null);
    const [yearVacations, setYearVacations] = useState(new Date().getFullYear())

    function fetchVacations(year) {
        getVacations(year)
            .then(data => setVacations(data))
            .catch(error => {
                setSnackbarMessage(t("error_fetching_vacations"));
                setOpenSnackbar(true);
            })
            .finally(() => setIsLoading(false));
    }

    useEffect(() => {
        if (yearVacations) {
            fetchVacations(yearVacations);
        }
    }, [t]);

    const handleAddVacation = () => {
        const newVacation = {description: "", amount: 0};
        setVacations([...vacations, newVacation]);
        setIsEditing(vacations.length);
    };

    const handleEdit = (index, key, value) => {
        const updatedVacations = [...vacations];
        const year = value.getFullYear();
        const month = String(value.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(value.getDate()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`;

        console.log(formattedDate)
        console.log(formatDate(value))
        updatedVacations[index][key] = formattedDate;
        setVacations(updatedVacations);
    };

    const handleSave = (index) => {
        const vacation = vacations[index];

        if(!vacation.fromDate && !vacation.toDate){
            return;
        }

        createVacation(vacation)
            .then(newFee => {
                fetchVacations(yearVacations);
            })
            .catch(reason => {
                console.log(reason)
                setSnackbarMessage(t("error_creating_vacations"));
                setOpenSnackbar(true);
            });
        setIsEditing(null);
    };

    const handleDelete = (index) => {
        const vacation = vacations[index];
        if (vacation) {
            deleteVacation(vacation)
                .then(() => fetchVacations())
                .catch(() => {
                    setSnackbarMessage(t("error_deleting_vacations"));
                    setOpenSnackbar(true);
                });
        }
    };

    const parseDate = (dateString) => new Date(dateString);

    return (
        <>
            {isLoading ? <Loading/> : (
                <Grid container spacing={2} style={{padding: '100px'}}>
                    <Grid item xs={12}>
                        <Typography variant="h6" style={{marginBottom: '20px'}}>{t("vacations")}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Select
                            value={yearVacations}
                            displayEmpty
                            inputProps={{'aria-label': 'Without label'}}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: 200
                                    },
                                },
                            }}
                            onChange={(event) => {
                                setYearVacations(event.target.value);
                                fetchVacations(event.target.value);
                            }}>
                            <MenuItem value=""><em>None</em></MenuItem>

                            {Array.from({length: 4}, (_, i) => (new Date().getFullYear() - 1) + i)
                                .map(option => (
                                    <MenuItem key={option} value={option}>{option}</MenuItem>
                                ))}
                        </Select>
                        <Button onClick={handleAddVacation} variant="contained" startIcon={<AddIcon/>} style={{marginLeft: 20}}>
                            {t("add_new_vacations")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="service fee table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("start_date")}</TableCell>
                                        <TableCell>{t("end_date")}</TableCell>
                                        <TableCell>{t("actions")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vacations.map((vacation, index) => (
                                        <TableRow key={index}>
                                            <TableCell>
                                                {isEditing === index ? (
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                                                        <DatePicker
                                                            label={t("start_date")}
                                                            value={parseDate(vacation.fromDate)}
                                                            onChange={(newValue) => handleEdit(index, "fromDate", newValue)}
                                                            inputFormat="dd-MM-yyyy"
                                                            renderInput={(params) => <TextField {...params} fullWidth/>}
                                                        />
                                                    </LocalizationProvider>
                                                ) : (
                                                    formatDate(vacation.fromDate)
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {isEditing === index ? (
                                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                                                        <DatePicker
                                                            label={t("end_date")}
                                                            value={parseDate(vacation.toDate)}
                                                            onChange={(newValue) => handleEdit(index, "toDate", newValue)}
                                                            renderInput={(params) => <TextField {...params} fullWidth/>}
                                                        />
                                                    </LocalizationProvider>
                                                ) : (
                                                    formatDate(vacation.toDate)
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                {isEditing === index ? (
                                                    <>
                                                        <Button onClick={() => handleSave(index)} variant="contained" color="primary">
                                                            {t('save')}
                                                        </Button>
                                                        <Button onClick={() => setIsEditing(null)} color="secondary">
                                                            {t('cancel')}
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <>
                                                        <IconButton onClick={() => handleDelete(index)} color="error">
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <GetErrorSnackbar openSnackbar={openSnackbar} snackbarMessage={snackbarMessage} setOpenSnackbar={setOpenSnackbar}/>
                </Grid>
            )}
        </>
    );
};

export default withAuthenticationRequired(Vacations, {
    onRedirecting: () => <Loading/>,
    returnTo: '/login'
});
