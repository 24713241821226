import {withAuthenticationRequired} from "@auth0/auth0-react";
import Loading from "../../common/Loading";
import React, {useContext, useEffect, useState} from "react";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import nl from 'date-fns/locale/nl';
import {Alert, Button, Card, CardActions, CardContent, CardHeader, Typography} from "@mui/material";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import {addDays} from "date-fns";
import {GlobalStateContext} from "../../../state/GlobalStateProvider";
import {useTranslation} from "react-i18next";


function CheckOutDate({reservationEnd, updateReservationStep}) {
    const { t } = useTranslation();
    const {globalState, updateGlobalState} = useContext(GlobalStateContext);
    const [dateUnavailable, setDateUnavailable] = useState(false);

    useEffect(() => {
        if (globalState.reservationMeta.toDate) {
            setDateUnavailable(globalState.reservationMeta.toDate < globalState.reservationMeta.fromDate);
        }
    }, [globalState.reservationMeta.fromDate, globalState.reservationMeta.toDate]);

    return (
        <Card>
            <CardHeader title={<Typography color="secondary" variant="h5">{t('selectCheckOutDate')}</Typography>}/>
            <CardContent>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={nl}>
                    <DatePicker
                        label={t('checkOutDateLabel')}
                        value={globalState.reservationMeta.toDate}
                        minDate={addDays(globalState.reservationMeta.fromDate, 1)}
                        maxDate={reservationEnd}
                        onChange={(newValue) => {
                            updateGlobalState({reservationMeta: {toDate: newValue}})
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                {dateUnavailable && (<Alert sx={{marginTop: 2}} severity="warning">{t('dateBeforeWarning')}</Alert>)}
            </CardContent>
            <CardActions style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <>
                    <Button onClick={() => updateReservationStep(1)} variant="contained">
                        {t('previous')}
                    </Button>
                    <Button disabled={dateUnavailable || !globalState.reservationMeta.toDate} variant="contained"
                            onClick={() => updateReservationStep(3)}>
                        {t('next')}
                    </Button>
                </>
            </CardActions>
        </Card>
    )
}


export default withAuthenticationRequired(CheckOutDate, {
    onRedirecting: () => <Loading/>,
    returnTo: '/nieuwe-reservatie'
});
